import React from "react";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";


const Page = () => {
  return (
    <LessonLayout
      current_step={13}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 13</H1>

        <p>Before we make a decision about our health, we need to think about what the benefits may be and what the risks may be.</p>
        <p>Read the following article to think about risks and benefits. (Your teacher will tell you which option to choose.) In your science notebook, write some of the benefits and risks that the woman in the story faced. Be prepared to share your ideas in a class discussion.</p>

        <div className="mt-5 flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3">
          <Button
            action="secondary"
            color="purple"
            href="/middle-school/lesson-4/step-13a"
            title="Option 1"
          >
            Option 1
          </Button>

          <Button
            action="secondary"
            color="purple"
            href="/middle-school/lesson-4/step-13b"
            title="Option 2"
          >
            Option 2
          </Button>
        </div>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

